// @flow

/**
 * Module dependencies.
 */

import { Button, Heading, Text } from '@slyk/design-system';
import { Icon } from 'react-components/media';
import { Trans } from 'react-i18next';
import { color, media } from 'react-components/styles';
import { differenceInDays, isPast } from 'date-fns';
import { formatCurrency } from 'payspace/client/core/utils/formatter';
import { prop, theme } from 'styled-tools';
import { useTranslate } from '@slyk/i18n';
import BigNumber from 'bignumber.js';
import React, { type Node, useMemo } from 'react';
import clockIcon from 'payspace/client/assets/svg/16/clock.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  date?: Date,
  defaultCurrency: Object,
  funded: number | string,
  goal: number | string,
  onClickInvest: () => void
};

/**
 * `CardWrapper` styled component.
 */

const CardWrapper = styled.div`
  background-color: ${color('gray200')};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px 16px;
`;

/**
 * `TopInfo` styled component.
 */

const TopInfo = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: space-between;

  ${media.min('xxs')`
    gap: 8px;
  `}
`;

/**
 * `ProgressBarWrapper` styled component.
 */

const ProgressBarWrapper = styled.div`
  background-color: ${color('gray400')};
  border-radius: 999px;
  height: 6px;
`;

/**
 * `Bar` styled component.
 */

const Bar = styled.div`
  background-color: ${color('blue500')};
  border-radius: 999px;
  height: 6px;
  transition: width ${theme('transitions.fast')};
  width: ${prop('width')}%;
`;

/**
 * `BottomInfo` styled component.
 */

const BottomInfo = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
`;

/**
 * Get percentage donated.
 */

function getPercentageDonated(donated?: string | number, goal?: string | number) {
  if (!donated || !goal || new BigNumber(donated).isZero() || new BigNumber(goal).isZero()) {
    return 0;
  }

  return new BigNumber(donated)
    .multipliedBy(100)
    .dividedBy(new BigNumber(goal))
    .toFixed(0, BigNumber.ROUND_DOWN);
}

/**
 * Get days.
 */

function getDays(date: Date): number {
  return isPast(date) ? 0 : differenceInDays(date, Date.now());
}

/**
 * Export `ProgressCard` component.
 */

export function ProgressCard(props: Props): Node {
  const { date, defaultCurrency, funded, goal, onClickInvest } = props;
  const { i18n, translate } = useTranslate();
  const locale = i18n.language;
  const percentage = useMemo(() => getPercentageDonated(funded, goal), [funded, goal]);
  const dateLabel = useMemo(() => {
    return date ? translate('progressCard.daysLeft', { days: getDays(date) }) : translate('progressCard.notStarted');
  }, [date, translate]);

  const formattedGoal = useMemo(() => {
    return formatCurrency(goal, { ...defaultCurrency, locale });
  }, [defaultCurrency, goal, locale]);

  const formattedFunded = useMemo(() => {
    return formatCurrency(funded, { ...defaultCurrency, locale });
  }, [defaultCurrency, funded, locale]);

  return (
    <CardWrapper>
      <TopInfo>
        <Heading
          as={'span'}
          color={color('blue500')}
          fontWeight={600}
          level={'h4'}
        >
          {formattedFunded}
        </Heading>

        <Text color={color('blue500')}>
          <Trans
            components={[(
              <Text
                fontWeight={600}
                key={'b'}
              />
            )]}
            i18nKey={'progressCard.goal'}
            values={{ goal: formattedGoal, percentage }}
          />
        </Text>
      </TopInfo>

      <ProgressBarWrapper>
        <Bar width={percentage > 100 ? 100 : percentage} />
      </ProgressBarWrapper>

      <BottomInfo>
        <Icon
          aria-hidden
          color={color('gray900')}
          icon={clockIcon}
          size={'16px'}
        />

        <Text
          color={color('gray900')}
          fontWeight={500}
          size={'small'}
        >
          {dateLabel}
        </Text>
      </BottomInfo>

      <Button
        onClick={onClickInvest}
        size={'large'}
      >
        {translate('common.actions.joinToInvest')}
      </Button>
    </CardWrapper>
  );
}
