// @flow

/**
 * Module dependencies.
 */

import { Heading, typography } from '@slyk/design-system';
import { ProgressCard } from './progress-card';
import { color, media } from 'react-components/styles';
import { prop } from 'styled-tools';
import Container from 'payspace/client/components/home/container';
import React, { type Node } from 'react';
import ReactMarkdown from 'react-markdown';
import placeholderImage from 'payspace/client/assets/images/home/placeholder.webp';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  defaultCurrency: Object,
  endedAt?: Date,
  funded: number | string,
  goal: number | string,
  lead: string,
  navbarHeight: number,
  onClickInvest: () => void,
  projectImage?: string,
  tagLabel?: string | null,
  title: string
};

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.header`
  --navbar-height: ${prop('paddingTop', 122)}px;

  display: flex;
  padding-top: var(--navbar-height);
  position: relative;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;

  ${media.min('xxs')`
    padding-top: 40px;
  `}

  ${media.min('xs')`
    gap: 24px 32px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, max-content);
    padding-top: 80px;
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, max-content);
  `}
`;

/**
 * `Info` styled component.
 */

const Info = styled.div`
  display: flex;
  flex-direction: column;

  ${media.min('xs')`
    grid-column: 2 / -2;
    grid-row: 1;
  `}

  ${media.min('lg')`
    grid-column: 1 / span 6;
    padding-right: 32px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Heading).attrs({ as: 'h1', level: 'h2' })`
  font-weight: 600;
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(ReactMarkdown)`
  ${typography.styles.textMedium};

  padding: 16px 0;

  b, strong {
    font-weight: 600;
  }

  p {
    margin: 0;
  }
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  aspect-ratio: 5 / 4;
  background-color: ${color('gray200')};
  border-radius: 6px;
  height: auto;
  object-fit: cover;
  width: 100%;

  ${media.min('xs')`
    grid-column: 2 / -2;
    grid-row: 2;
  `}

  ${media.min('lg')`
    grid-column: 7 / -1;
    grid-row: 1 / span 2;
  `}
`;

/**
 * `ProgressCardWrapper` styled component.
 */

const ProgressCardWrapper = styled.div`
  ${media.min('xs')`
    grid-column: 2 / -2;
    grid-row: 3;
  `}

  ${media.min('lg')`
    grid-column: 1 / span 5;
    grid-row: 2;
  `}
`;

/**
 * Export `Header` component.
 */

export function Header(props: Props): Node {
  const { defaultCurrency, endedAt, funded, goal, lead, navbarHeight, onClickInvest, projectImage, title } = props;

  return (
    <HeaderWrapper paddingTop={navbarHeight}>
      <Container>
        <ContentWrapper>
          <Info>
            <Title>
              {title}
            </Title>

            <Lead remarkPlugins={[remarkGfm]}>
              {lead}
            </Lead>
          </Info>

          <Image
            alt={''}
            aria-hidden
            src={projectImage ?? placeholderImage}
          />

          <ProgressCardWrapper>
            <ProgressCard
              date={endedAt}
              defaultCurrency={defaultCurrency}
              funded={funded}
              goal={goal}
              onClickInvest={onClickInvest}
            />
          </ProgressCardWrapper>
        </ContentWrapper>
      </Container>
    </HeaderWrapper>
  );
}
