// @flow

/**
 * Module dependencies.
 */

import {
  Button,
  Heading,
  Text,
  typography,
  useImageSources
} from '@slyk/design-system';

import { Icon } from 'react-components/media';
import { type RewardAsset } from '@slyk/types';
import { type TaskType, resolveImageUrl } from './task';
import { color, media } from 'react-components/styles';
import { formatCurrency } from 'payspace/client/core/utils/formatter';
import { prop, theme } from 'styled-tools';
import { useTranslate } from '@slyk/i18n';
import BigNumber from 'bignumber.js';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useMemo } from 'react';
import earnRewardsInviteIcon from 'payspace/client/assets/svg/earn-rewards-invite.svg';
import noImage40 from 'payspace/client/assets/svg/40/no-image.svg';
import shareIcon24 from 'payspace/client/assets/svg/24/share.svg';
import styled from 'styled-components';
import userExample1Image from 'payspace/client/assets/images/earn-rewards/user-example-1.webp';
import userExample2Image from 'payspace/client/assets/images/earn-rewards/user-example-2.webp';
import userExample3Image from 'payspace/client/assets/images/earn-rewards/user-example-3.webp';

/**
 * `ReferralProgram` type.
 */

type ReferralProgram = {|
  referralEarnEnabled?: boolean,
  referralEarnPercentage?: string,
  referralEnabled?: boolean,
  referralLimit?: string,
  referralPercentage?: string
|};

/**
 * `Props` type.
 */

type Props = {|
  cardDirection: 'column' | 'row';
  name: string,
  onGoToGetAccess: () => void,
  referralProgram: ReferralProgram,
  rewardAsset: RewardAsset,
  task: TaskType
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  ${media.min('ms')`
    border: 1px solid ${color('gray300')};
    border-radius: 12px;
    padding: 16px;
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Heading).attrs({ as: 'h3', level: 'h4' })`
  color: ${color('primary')};
  font-weight: 600;
  margin-bottom: 16px;

  ${media.min('xs')`
    margin-bottom: 32px;
  `};
`;

/**
 * `CardWrapper` styled component.
 */

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.min('ms')`
    flex-direction: ${prop('direction')};
  `}
`;

/**
 * `Card` styled component.
 */

const Card = styled.button`
  appearance: none;
  background-color: ${color('gray200')};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  height: min-content;
  padding: 24px 8px;
  transition: background-color ${theme('animations.defaultTransition')};
  width: 100%;

  ${media.min('ms')`
    padding: 24px;
  `}

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background-color: ${color('gray300')};
  }
`;

/**
 * `CardTitle` styled component.
 */

const CardTitle = styled(Heading).attrs({ level: 'h4' })`
  font-weight: 600;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ size: 'normal' })`
  color: ${color('secondary')};
  padding-top: 8px;
`;

/**
 * `UserListExample` styled component.
 */

const UserListExample = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding-top: 40px;
`;

/**
 * `UserAvatarItem` styled component.
 */

const UserAvatarItem = styled.li`
  background-color: ${color('white')};
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(18, 39, 68, 0.13);
  height: 70px;
  overflow: hidden;
  padding: 6px;
  width: 70px;

  ${media.min('ms')`
    height: 120px;
    width: 120px;
  `}

  &:not(:first-child) {
    margin-left: -16px;
  }
`;

/**
 * `UserImage` styled component.
 */

const UserImage = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

/**
 * `InviteIcon` styled component.
 */

const InviteIcon = styled(Icon)`
  color: ${color('success')};
  height: auto;
  width: 120px;

  ${media.min('ms')`
    width: 146px;
  `}
`;

/**
 * `ShareLinkButton` styled component.
 */

const ShareLinkButton = styled(Button).attrs({
  colorTheme: 'success',
  fullWidth: true,
  size: 'large'
})`
  align-items: center;
  display: flex;
  font-weight: 500;
  gap: 8px;
  justify-content: center;
  margin-top: 32px;
  pointer-events: none;
`;

/**
 * `EarnInfo` styled component.
 */

const EarnInfo = styled(Text).attrs({ size: 'normal' })`
  padding: 32px 32px 0;
`;

/**
 * `TaskContent` styled component.
 */

const TaskContent = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: repeat(3, max-content);
  justify-content: center;
  text-align: center;
`;

/**
 * `TaskFigure` styled component.
 */

const TaskFigure = styled.figure`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-top: 8px;
`;

/**
 * `Caption` styled component.
 */

const Caption = styled.figcaption`
  ${typography.styles.textNormal}
`;

/**
 * `TaskImage` styled component.
 */

const TaskImage = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${color('gray500')};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 66px;
  justify-content: center;
  margin-bottom: 8px;
  overflow: hidden;
  width: 66px;

  ${media.min('ms')`
    height: 88px;
    width: 88px;
  `}
`;

/**
 * `TaskAmount` styled component.
 */

const TaskAmount = styled(Heading).attrs({ as: 'p', level: 'h3' })`
  color: ${color('success500')};
  font-weight: 600;
  margin-bottom: 8px;
`;

/**
 * `EarnRewards` component.
 */

function EarnRewards(props: Props): Node {
  const {
    cardDirection,
    name,
    onGoToGetAccess,
    referralProgram,
    rewardAsset,
    task
  } = props;

  const taskMediaSources = useImageSources(resolveImageUrl(task?.media), '96x');
  const { referralEarnEnabled, referralEarnPercentage, referralLimit, referralPercentage } = referralProgram;
  const { i18n, translate } = useTranslate();
  const locale = i18n.language;
  const hasReferralLimit = new BigNumber(referralLimit).isGreaterThan(0);
  const referralLimitAmount = useMemo(() => {
    return formatCurrency(referralLimit ?? 0, { ...rewardAsset, locale });
  }, [locale, referralLimit, rewardAsset]);

  return (
    <Wrapper>
      <Lead>
        {translate('earnRewards.lead')}
      </Lead>

      <CardWrapper direction={cardDirection}>
        <Card onClick={onGoToGetAccess}>
          <RawHtml
            as={'h3'}
            element={CardTitle}
          >
            {translate(`earnRewards.invite.title${hasReferralLimit ? 'WithLimit' : ''}`, {
              limit: referralLimitAmount,
              name
            })}
          </RawHtml>

          <RawHtml element={Description}>
            {translate('earnRewards.invite.description', {
              percentage: referralPercentage
            })}
          </RawHtml>

          <UserListExample>
            <UserAvatarItem>
              <UserImage src={userExample1Image} />
            </UserAvatarItem>

            <UserAvatarItem>
              <UserImage src={userExample2Image} />
            </UserAvatarItem>

            <UserAvatarItem>
              <UserImage src={userExample3Image} />
            </UserAvatarItem>
          </UserListExample>

          <InviteIcon
            aria-hidden
            icon={earnRewardsInviteIcon}
          />

          {referralEarnEnabled && (
            <RawHtml element={EarnInfo}>
              {translate('earnRewards.invite.earnInfo', {
                percentage: referralEarnPercentage
              })}
            </RawHtml>
          )}

          <ShareLinkButton>
            <Icon
              aria-hidden
              icon={shareIcon24}
              size={'24px'}
            />

            {translate('earnRewards.invite.shareLink')}
          </ShareLinkButton>
        </Card>

        {task && (
          <Card onClick={onGoToGetAccess}>
            <TaskContent>
              <CardTitle>
                {translate('earnRewards.completeTask.title')}
              </CardTitle>

              <TaskFigure>
                <ImageWrapper>
                  {taskMediaSources?.retinaUrl ? (
                    <TaskImage
                      loading={'lazy'}
                      src={taskMediaSources.retinaUrl}
                    />
                  ) : (
                    <Icon
                      aria-hidden
                      color={color('white')}
                      icon={noImage40}
                      size={'40px'}
                    />
                  )}
                </ImageWrapper>

                <Caption>
                  {task.name}
                </Caption>
              </TaskFigure>

              <TaskAmount>
                {translate('earnRewards.completeTask.description', {
                  amount: task.normalizedAmount
                })}
              </TaskAmount>
            </TaskContent>
          </Card>
        )}
      </CardWrapper>
    </Wrapper>
  );
}

/**
 * Export `EarnRewards` component.
 */

export default EarnRewards;
