// @flow

/**
 * Module dependecies.
 */

import { Icon } from 'react-components/media';
import type { ImageSource, Media } from '@slyk/types';
import { Text, useImageSources } from '@slyk/design-system';
import { color, media } from 'react-components/styles';
import { theme } from 'styled-tools';
import { useBreakpoint } from '@slyk/breakpoint';
import React, { type Node } from 'react';
import noImage40 from 'payspace/client/assets/svg/40/no-image.svg';
import noImage56 from 'payspace/client/assets/svg/56/no-image.svg';
import styled from 'styled-components';

/**
 * Export `TaskType` type.
 */

export type TaskType = {|
  amount: string,
  id: string,
  media: ?ImageSource,
  name: string,
  normalizedAmount: string
|};

/**
 * `Props` type.
 */

type Props = {|
  ...TaskType,
  onClick: SyntheticMouseEvent<any> => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button`
  align-items: center;
  appearance: none;
  background-color: ${color('white')};
  border: none;
  border-radius: ${theme('dimensions.borderRadius')}px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px;
  overflow: hidden;
  padding: 0;
  text-align: start;
  transition: background-color ${theme('animations.defaultTransition')};
  width: 100%;

  &:focus,
  &:hover {
    background-color: ${color('gray300')};
    outline: none;
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${color('gray500')};
  border-radius: ${theme('dimensions.borderRadius')}px;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  object-fit: cover;
  object-position: center;
`;

/**
 * `Details` styled component.
 */

const Details = styled.div`
  overflow: hidden;
  padding: 7px 8px;

  ${media.min('ms')`
    padding: 16.5px 16px;
  `}
`;

/**
 * `Name` styled component.
 */

const Name = styled(Text)`
  color: ${color('gray800')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.min('ms')`
    padding-bottom: 5px;
  `}
`;

/**
 * Export `resolveImageUrl`.
 */

export function resolveImageUrl(media: ?Media): ?string {
  if (!media || media?.status === 'processing') {
    return null;
  }

  // $FlowFixMe
  const { thumbnailUrl, type, url } = media;

  switch (type) {
    case 'image':
      return url;

    case 'video':
      return thumbnailUrl;

    default:
      return null;
  }
}

/**
 * `Task` component.
 */

function Task(props: Props): Node {
  const { media, name, normalizedAmount, onClick } = props;
  const isDesktop = useBreakpoint('ms', 'min');
  const imageSources = useImageSources(resolveImageUrl(media), '96x');

  return (
    <Wrapper onClick={onClick}>
      <ImageWrapper>
        {imageSources?.retinaUrl ? (
          <Image
            alt={name ?? ''}
            height={'100%'}
            loading={'lazy'}
            src={imageSources.retinaUrl}
            width={'100%'}
          />
        ) : (
          <Icon
            aria-hidden
            color={color('white')}
            icon={isDesktop ? noImage56 : noImage40}
            size={isDesktop ? '56px' : '40px'}
          />
        )}
      </ImageWrapper>

      <Details>
        <Name title={name}>
          {name}
        </Name>

        <Text
          color={color('success')}
          size={'small'}
        >
          {`+ ${normalizedAmount}`}
        </Text>
      </Details>
    </Wrapper>
  );
}

/**
 * Export `Task` component.
 */

export default Task;
