// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { Link, Text } from '@slyk/design-system';
import { color, media } from 'react-components/styles';
import { sortedSocialNetworks } from '@slyk/social-networks';
import { theme } from 'styled-tools';
import { useTranslate } from '@slyk/i18n';
import React, { type Node, useMemo } from 'react';
import airchatIcon24 from 'payspace/client/assets/svg/24/airchat.svg';
import discordIcon24 from 'payspace/client/assets/svg/24/discord.svg';
import emailIcon24 from 'payspace/client/assets/svg/24/email.svg';
import facebookIcon24 from 'payspace/client/assets/svg/24/facebook.svg';
import instagramIcon24 from 'payspace/client/assets/svg/24/instagram.svg';
import linkedinIcon24 from 'payspace/client/assets/svg/24/linkedin.svg';
import mediumIcon24 from 'payspace/client/assets/svg/24/medium.svg';
import slackIcon24 from 'payspace/client/assets/svg/24/slack.svg';
import styled from 'styled-components';
import substackIcon24 from 'payspace/client/assets/svg/24/substack.svg';
import telegramIcon24 from 'payspace/client/assets/svg/24/telegram.svg';
import tiktokIcon24 from 'payspace/client/assets/svg/24/tiktok.svg';
import twitterIcon24 from 'payspace/client/assets/svg/24/twitter.svg';
import whatsappIcon24 from 'payspace/client/assets/svg/24/whatsapp.svg';

/**
 * Connect icons.
 */

const connectIcons = {
  airchat: airchatIcon24,
  discord: discordIcon24,
  email: emailIcon24,
  facebook: facebookIcon24,
  instagram: instagramIcon24,
  linkedin: linkedinIcon24,
  medium: mediumIcon24,
  slack: slackIcon24,
  substack: substackIcon24,
  telegram: telegramIcon24,
  tiktok: tiktokIcon24,
  twitter: twitterIcon24,
  whatsapp: whatsappIcon24
};

/**
 * Sorted connect list.
 */

const sortedConnectList = [
  'email',
  ...sortedSocialNetworks
];

/**
 * `Props` type.
 */

type Props = {|
  socialNetworks: {| [key: string]: string |}
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  margin-bottom: 32px;
  padding-top: 32px;

  ${media.min('xs')`
    margin-bottom: 120px;
    padding-top: 80px;
  `}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  ${media.min('xs')`
    padding: 24px;
  `}
`;

/**
 * `Name` styled component.
 */

const Name = styled(Text).attrs({ as: 'h5', size: 'normal' })`
  color: ${color('gray800')};
  font-weight: 600;
  text-align: center;
`;

/**
 * `ConnectList` styled component.
 */

const ConnectList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  text-align: center;

  ${media.min('ms')`
    gap: 38px;
  `}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  color: ${color('gray')};
  transition: color ${theme('animations.defaultTransition')};
`;

/**
 * `ConnectItemLink` styled component.
 */

const ConnectItemLink = styled(Link)`
  align-items: center;
  display: flex;

  &:focus,
  &:hover {
    ${StyledIcon} {
      color: ${color('primary')};
    }
  }
`;

/**
 * `Connect` component.
 */

function Connect({ socialNetworks }: Props): Node {
  const { translate } = useTranslate();
  const socialNetworksKeys = Object.keys(socialNetworks);
  const hasOnlyEmailNetwork = socialNetworksKeys.length === 1 && socialNetworksKeys[0] === 'email';
  const sortedList = useMemo(() => sortedConnectList
    .filter(key => !!socialNetworks[key])
    .map(key => {
      const value = socialNetworks[key];

      return {
        href: key === 'email' ? `mailto:${value}` : value,
        icon: connectIcons[key],
        label: translate(`socialNetworks.${key}`)
      };
    })
  , [socialNetworks, translate]);

  return (
    <Section>
      <Wrapper>
        <Name>
          {translate('connect.title')}
        </Name>

        <ConnectList>
          <>
            {!hasOnlyEmailNetwork && sortedList.map(({ href, icon, label }, index) => (
              <li key={index}>
                <ConnectItemLink
                  aria-label={label}
                  href={href}
                  rel={'noopener'}
                  target={'_blank'}
                >
                  <StyledIcon
                    aria-hidden
                    icon={icon}
                    size={'24px'}
                  />
                </ConnectItemLink>
              </li>
            ))}

            {hasOnlyEmailNetwork && (
              <Text color={color('gray800')}>
                {socialNetworks[socialNetworksKeys[0]]}
              </Text>
            )}
          </>
        </ConnectList>
      </Wrapper>
    </Section>
  );
}

/**
 * Export `Connect` component.
 */

export default Connect;
