// @flow

/**
 * Module dependencies.
 */

import { Button, Heading, Text } from '@slyk/design-system';
import { type RewardAsset } from '@slyk/types';
import { color, media } from 'react-components/styles';
import { formatCurrency } from 'payspace/client/core/utils/formatter';
import { theme } from 'styled-tools';
import { useBreakpoint } from '@slyk/breakpoint';
import { useTranslate } from '@slyk/i18n';
import BigNumber from 'bignumber.js';
import Container from 'payspace/client/components/home/container';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useMemo } from 'react';
import Task, { type TaskType } from './task';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  name: string,
  onGoToGetAccess: () => void,
  referralEnabled?: boolean,
  rewardAsset: RewardAsset;
  tasks: Array<TaskType>
|};

/**
 * `TasksWrapper` styled component.
 */

const TasksWrapper = styled.section`
  padding-top: 80px;

  ${media.min('ms')`
    margin: 0 24px;
  `}

  ${media.min('lg')`
    margin: 0 32px;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  background-color: ${color('gray200')};
  border-radius: ${theme('dimensions.borderRadius')}px;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  max-width: 1192px;
  padding: 40px 16px;

  ${media.min('ms')`
    grid-column-gap: 32px;
    grid-template-columns: repeat(8, 1fr);
    padding: 56px 0 56px;
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(12, 1fr);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Heading).attrs({ as: 'h2', fontWeight: 600, level: 'h3' })`
  grid-column: 1 / -1;
  text-align: center;

  ${media.min('xs')`
    grid-column: 2 / -2;
  `}

  ${media.min('lg')`
    margin-bottom: 8px;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ size: 'normal' })`
  color: ${color('secondary')};
  grid-column: 1 / -1;
  margin: 0 auto;
  padding: 16px 0;
  text-align: center;

  strong {
    font-weight: 700;
  }

  ${media.min('xs')`
    grid-column: 2 / -2;
  `}

  ${media.min('lg')`
    padding: 0;
  `}
`;

/**
 * `TasksList` styled component.
 */

const TasksList = styled.ul`
  display: grid;
  grid-column: 1 / -1;
  grid-row-gap: 8px;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: 45px 0;

  ${media.min('xs')`
    grid-column: 2 / -2;
  `}

  ${media.min('md')`
    grid-gap: 8px 32px;
    grid-template-columns: repeat(2, minmax(min(50%, 478px), 1fr));
    padding: 48px 0;
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
`;

/**
 * `TaskList` component.
 */

function TaskList(props: Props): Node {
  const { name, onGoToGetAccess, referralEnabled, rewardAsset, tasks } = props;
  const { i18n, translate } = useTranslate();
  const locale = i18n.language;
  const tasksWithReferralKey = referralEnabled ? 'withReferral.' : '';
  const isMobile = useBreakpoint('xs', 'max');
  const referralTotalDiscountAmount = useMemo(() => {
    const referralTotalDiscount = tasks
      .reduce((total: BigNumber, { amount }) => {
        return total.plus(amount);
      }, new BigNumber(0));

    return formatCurrency(referralTotalDiscount, { ...rewardAsset, locale });
  }, [locale, rewardAsset, tasks]);

  return (
    <TasksWrapper>
      <StyledContainer>
        <RawHtml
          as={'h5'}
          element={Title}
        >
          {translate(`earnRewards.tasks.${tasksWithReferralKey}title`, {
            amount: referralTotalDiscountAmount,
            name
          })}
        </RawHtml>

        <RawHtml element={Description}>
          {translate(`earnRewards.tasks.${tasksWithReferralKey}description`, {
            amount: referralTotalDiscountAmount,
            name
          })}
        </RawHtml>

        <TasksList>
          {tasks.map(task => (
            <li key={task?.id}>
              <Task
                onClick={onGoToGetAccess}
                {...task}
              />
            </li>
          ))}
        </TasksList>

        <ButtonWrapper>
          <Button
            fullWidth={isMobile}
            onClick={onGoToGetAccess}
            size={'large'}
          >
            {translate('earnRewards.startEarning')}
          </Button>
        </ButtonWrapper>
      </StyledContainer>
    </TasksWrapper>
  );
}

/**
 * Export `TaskList` component.
 */

export default TaskList;
