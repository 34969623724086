// @flow

/**
 * Module dependencies.
 */

import { Heading, Text, useImageSources } from '@slyk/design-system';
import { Icon } from 'react-components/media';
import { type Product as ProductType, unitsOfAccount } from '@slyk/types';
import { camelCase, range } from 'lodash';
import { color, media } from 'react-components/styles';
import { getImageSources } from 'payspace/client/core/utils/image';
import { ifProp, theme } from 'styled-tools';
import { useBreakpoint } from '@slyk/breakpoint';
import { useTranslate } from '@slyk/i18n';
import React, { type Node } from 'react';
import noImage32Icon from 'payspace/client/assets/svg/32/no-image.svg';
import noImage72Icon from 'payspace/client/assets/svg/72/no-image.svg';
import styled, { css } from 'styled-components';

/**
 * `ProductHighlights` type.
 */

type ProductHighlights = {
  ...ProductType,
  displayPrice: string,
  displayPriceConverted: string | null
};

/**
 * `Props` type.
 */

type Props = {|
  hasEarnRewards: boolean,
  onGoToGetAccess: () => void,
  products: Array<ProductHighlights>
|};

/**
 * Unit of account types.
 */

const unitOfAccountTypes = Object.keys(unitsOfAccount);

/**
 * Max highlight items.
 */

const maxHighlightItems = 4;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  ${media.min('ms')`
    border: 1px solid ${color('gray300')};
    border-radius: 12px;
    padding: 16px;
  `}
`;

/**
 * `Products` styled component.
 */

const Products = styled.ul`
  display: grid;
  gap: 20px 8px;
  grid-template-columns: 1fr;

  ${media.min('xxs')`
    gap: 24px 16px;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min('ms')`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.min('lg')`
    grid-template-columns: repeat(4, 1fr);

    ${ifProp('hasEarnRewards', css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    `)}
  `}
`;

/**
 * `ProductImageButton` styled component.
 */

const ProductImageButton = styled.button.attrs({ type: 'button' })`
  appearance: none;
  border: 1px solid ${color('gray200')};
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(25, 28, 30, 0.07);
  cursor: pointer;
  padding: 0;
  width: 100%;

  &:hover,
  &:focus {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
    outline: none;
  }
`;

/**
 * `ProductImage` styled component.
 */

const ProductImage = styled.img`
  aspect-ratio: 3 / 4;
  border-radius: inherit;
  display: block;
  height: auto;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

/**
 * `ProductInformation` styled component.
 */

const ProductInformation = styled.div`
  display: grid;
  padding-top: 4px;

  ${media.min('lg')`
    grid-row-gap: 4px;
    padding-top: 10px;
  `}
`;

/**
 * `ProductName` styled component.
 */

const ProductName = styled(Text)`
  color: ${color('secondary')};
  font-weight: 700;
  word-break: break-word;
`;

/**
 * `ProductPrice` styled component.
 */

const ProductPrice = styled(Text).attrs({ size: 'normal' })`
  color: ${color('gray700')};
  word-break: break-word;
`;

/**
 * `ProductAdditionalInfo` styled component.
 */

const ProductAdditionalInfo = styled.span`
  margin-left: 3px;
`;

/**
 * `PriceConverted` styled component.
 */

const PriceConverted = styled(Text).attrs({ size: 'normal' })`
  color: ${color('gray700')};
  padding-top: 2px;
`;

/**
 * `DefaultProductItem` styled component.
 */

const DefaultProductItem = styled.li`
  display: none;

  &:last-child {
    ${ifProp('isVisible', css`
      display: block;
    `)}
  }

  ${media.min('xs')`
    &:last-child {
      display: none;
    }

    &:nth-child(-n+3) {
      display: block;
    }
  `}

  ${media.min('ms')`
    &:not(:nth-child(n+5)) {
      display: block;
    }
  `}
`;

/**
 * `DefaultProductImageWrapper` styled component.
 */

const DefaultProductImageWrapper = styled.div`
  align-items: center;
  aspect-ratio: 3 / 4;
  background-color: ${color('gray300')};
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(25, 28, 30, 0.07);
  display: flex;
  justify-content: center;
  transition: background-color ${theme('animations.defaultTransition')};

  ${media.min('ms')`
    background-color: ${color('gray200')};
  `}
`;

/**
 * Product line.
 */

const productLine = css`
  background-color: ${color('gray300')};
  border-radius: 2px;
  height: 13px;
  transition: background-color ${theme('animations.defaultTransition')};

  ${media.min('ms')`
    background-color: ${color('gray200')};
  `}
`;

/**
 * `ProductNameLine` styled component.
 */

const ProductNameLine = styled.div`
  ${productLine}

  width: 100%;
`;

/**
 * `ProductAmountLine` styled component.
 */

const ProductAmountLine = styled.div`
  ${productLine}

  width: 35%;
`;

/**
 * `LinesWrapper` styled component.
 */

const LinesWrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  padding-top: 10px;

  ${media.min('lg')`
    padding-top: 20px;
  `}
`;

/**
 * `ProductProps` type.
 */

type ProductProps = {|
  onGoToGetAccess: () => void,
  product: ProductHighlights
|};

/**
 * `Product` component.
 */

function Product(props: ProductProps): Node {
  const { onGoToGetAccess, product } = props;
  const { displayPrice, displayPriceConverted, location, name, thumbnail, unitOfAccount } = product;
  const { translate } = useTranslate();
  // $FlowFixMe
  const url = thumbnail?.thumbnailUrl ?? thumbnail?.url;
  const imageSources = useImageSources(url, '128x');
  const noImageSources = getImageSources('no-image/product', 'webp');

  return (
    <li>
      <ProductImageButton onClick={onGoToGetAccess}>
        <ProductImage
          alt={url ? translate('highlights.image', { name }) : ''}
          height={'100%'}
          loading={'lazy'}
          src={imageSources?.defaultUrl ?? noImageSources.defaultUrl}
          srcSet={imageSources?.retinaUrl ?? noImageSources.retinaUrl}
          width={'100%'}
        />
      </ProductImageButton>

      <ProductInformation>
        <ProductName title={name}>
          {name}

          {location && (
            <ProductAdditionalInfo>
              {translate(`products.location.${camelCase(location)}`)}
            </ProductAdditionalInfo>
          )}
        </ProductName>

        <ProductPrice>
          {displayPrice}

          {unitOfAccount && (
            <ProductAdditionalInfo>
              {unitOfAccountTypes.includes(unitOfAccount) ? translate('products.per', {
                unitOfAccount: translate(`products.unitOfAccount.${unitOfAccount}`)
              }) : translate('products.per', { unitOfAccount })}
            </ProductAdditionalInfo>
          )}
        </ProductPrice>

        {!!displayPriceConverted && (
          <PriceConverted>
            {`(${displayPriceConverted})`}
          </PriceConverted>
        )}
      </ProductInformation>
    </li>
  );
}

/**
 * `Highlights` component.
 */

function Highlights(props: Props): Node {
  const { hasEarnRewards, onGoToGetAccess, products } = props;
  const { translate } = useTranslate();
  const productsLength = products.length;
  const defaultProducts = maxHighlightItems - productsLength % maxHighlightItems;
  const isDesktop = useBreakpoint('xl', 'min');

  return (
    <Wrapper>
      <Heading
        as={'h4'}
        color={color('primary')}
        fontWeight={600}
        level={'h4'}
        marginBottom={'32px'}
      >
        {translate('highlights.title')}
      </Heading>

      <Products hasEarnRewards={hasEarnRewards}>
        {products.map(product => {
          return (
            <Product
              key={product.id}
              onGoToGetAccess={onGoToGetAccess}
              product={product}
            />
          );
        })}

        {productsLength < maxHighlightItems && defaultProducts !== maxHighlightItems && range(defaultProducts)
          .map(index => (
            <DefaultProductItem
              isVisible={productsLength === 3 || productsLength === 1}
              key={index}
            >
              <DefaultProductImageWrapper>
                <Icon
                  aria-hidden
                  color={isDesktop ? color('gray500') : color('gray400')}
                  icon={isDesktop ? noImage72Icon : noImage32Icon}
                  size={isDesktop ? '72px' : '32px'}
                />
              </DefaultProductImageWrapper>

              <LinesWrapper>
                <ProductNameLine />

                <ProductAmountLine />
              </LinesWrapper>
            </DefaultProductItem>
          ))}
      </Products>
    </Wrapper>
  );
}

/**
 * Export `Highlights` component.
 */

export default Highlights;
